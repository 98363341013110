import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import auth from './auth/reducer';
import user from './user/reducer';
import gasStationUser from './gasStationUser/reducer';
import transaction from './transaction/reducer';
import organizations from './organizations/reducer';
import administrators from './administrators/reducer';
import reports from './reports/reducer';
import deposits from './deposits/reducer';
import moneyTransfer from './moneyTransfer/reducer';
import maps from './maps/reducer';
import hydrate from './hydrate/reducer';
import discountRate from './discountRate/reducer';
import fuel from './fuel/reducer';
import validateRefuel from './validateRefuel/reducer';

const reducers = combineReducers({
  menu,
  settings,
  auth,
  user,
  gasStationUser,
  transaction,
  organizations,
  administrators,
  reports,
  deposits,
  moneyTransfer,
  maps,
  hydrate,
  discountRate,
  fuel,
  validateRefuel,
});

export default reducers;
