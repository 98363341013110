import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const getUsersById = parameters =>
  api.get(`/admin/gasStation/user${objectToURLParams(parameters)}`);

export const getVehiclesById = parameters =>
  api.get(`/admin/gasStation/vehicle${objectToURLParams(parameters)}`);

export const listAdminstrators = parameters =>
  api.get(`/superAdmin/gasStation/user${objectToURLParams(parameters)}`);

export const registerAdministrator = body =>
  api.post('/superAdmin/gasStation/user', { ...body });

export const editAdministrator = payload =>
  api.put(`/superAdmin/gasStation/user/${payload.id}`, payload.body);

export const deleteAdministrator = id =>
  api.delete(`/superAdmin/gasStation/user/${id}`);
