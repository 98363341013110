import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  LOGIN_USER,
  LOGOUT_USER,
  PERSIST_REHYDRATE,
  AUTO_LOGOFF,
  FORGET_PASSWORD,
  forgetPasswordSuccess,
  forgetPasswordError,
  clearOrganizations,
} from '../actions';

import {
  loginUserSuccess,
  loginUserError,
  logoutUser,
  CHANGE_PASSWORD_REQUEST,
  changePasswordSuccess,
  changePasswordError,
  VALIDATE_SESSION_REQUEST,
  validateSessionSuccess,
  validateSessionError,
  RESET_PASSWORD,
  resetPasswordSuccess,
  resetPasswordError,
} from './actions';
import {
  getOrganizations,
  enableSuperAdmin,
  enableAdmin,
  onSelectOrganization,
} from '../organizations/actions';

function* loginWithEmailPassword(api, { payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  const loginUser = yield call(api.login, { identifier: email, password });

  if (loginUser.ok) {
    const { user, authHeader, scope } = loginUser.data;
    api.setHeader('Authorization', authHeader);

    const isSuperAdmin = scope.includes('SuperAdminGasStation');
    const isAdmin = scope.includes('AdminGasStation');

    if (!isAdmin && !isSuperAdmin) {
      yield put(
        loginUserError(
          'Você não tem permissão para acessar este painel, solicite a criação de um usuário Administrador, ou tente novamente com outras credenciais.'
        )
      );
      return;
    }

    if (isSuperAdmin) {
      yield put(enableSuperAdmin());
      yield put(getOrganizations());
    }

    if (isAdmin) {
      yield put(enableAdmin());
      yield put(
        onSelectOrganization({ id: scope[1], name: scope[scope.length - 1] })
      );
    }

    yield put(loginUserSuccess(authHeader, user));
    history.push('/');
  } else {
    const { data } = loginUser;

    let message = '';
    if (data && data.message.indexOf('Invalid Identifier') > -1) {
      message = 'Usuário e/ou senha inválidos.';
    } else if (data && data.message.indexOf('attempts reached') > -1) {
      message =
        'Usuário bloqueado por exceder o número máximo de tentativas. ' +
        'Tente novamente mais tarde.';
    } else {
      message = 'Ocorreu um erro ao tentar se autenticar.';
    }

    yield put(loginUserError(message));
  }
}

const logoutAsync = history => {
  history.push('/');
};

function* logout({ payload }) {
  const { history } = payload;
  try {
    yield call(logoutAsync, history);
    localStorage.removeItem('user_id');
  } catch (error) {
    window.console.error(error);
  }
}

function* autoLogoff({ payload }) {
  const { history } = payload;
  try {
    yield put(clearOrganizations());
    yield put(logoutUser(history));
  } catch (error) {
    window.console.error(error);
  }
}

function setAuthHeader(api, { payload }) {
  if (!payload) return;

  const { auth: authUser } = payload;
  if (authUser) {
    api.setHeader('Authorization', authUser.token);
  }
}

function* handleForgetPassword(api, { payload }) {
  try {
    const response = yield call(api.forgetPassword, payload.identifier);

    if (!response.ok) {
      throw new Error(response.problem);
    }

    yield put(forgetPasswordSuccess());
  } catch (error) {
    yield put(forgetPasswordError(error.message));
  }
}

function* changeUserPassword(api, { payload }) {
  const response = yield call(api.changePassword, payload);

  if (response.ok) {
    yield put(changePasswordSuccess());
  } else {
    yield put(changePasswordError(response.data.message));
  }
}

function* validateUserSession(api) {
  const response = yield call(api.validateSession);

  if (response.ok) {
    yield put(validateSessionSuccess());
  } else {
    yield put(validateSessionError(response.data.message));
  }
}

function* handleResetPassword(api, { payload }) {
  const { token, password } = payload;
  try {
    const response = yield call(api.resetPassword, token, password);

    if (!response.ok) {
      throw new Error(response.problem);
    }

    yield put(resetPasswordSuccess());
  } catch (error) {
    yield put(resetPasswordError(error.message));
  }
}

export function* watchPersistRehydrate(api) {
  yield takeEvery(PERSIST_REHYDRATE, setAuthHeader, api);
}

export function* watchLoginUser(api) {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword, api);
}

export function* watchChangePassword(api) {
  yield takeEvery(CHANGE_PASSWORD_REQUEST, changeUserPassword, api);
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

export function* watchAutoLogoffUser() {
  yield takeEvery(AUTO_LOGOFF, autoLogoff);
}

function* watchForgetPassword(api) {
  yield takeEvery(FORGET_PASSWORD, handleForgetPassword, api);
}

export function* watchValidateSession(api) {
  yield takeEvery(VALIDATE_SESSION_REQUEST, validateUserSession, api);
}

function* watchResetPassword(api) {
  yield takeEvery(RESET_PASSWORD, handleResetPassword, api);
}

export default function* rootSaga(api) {
  yield all([
    fork(watchPersistRehydrate, api),
    fork(watchLoginUser, api),
    fork(watchLogoutUser),
    fork(watchAutoLogoffUser),
    fork(watchForgetPassword, api),
    fork(watchChangePassword, api),
    fork(watchValidateSession, api),
    fork(watchResetPassword, api),
  ]);
}
