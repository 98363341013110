import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import * as organizations from './actions';

function* list(api) {
  const response = yield call(api.list);

  if (response.ok) {
    const userOrganizations = response.data.docs;
    yield put(organizations.onSuccess(userOrganizations));
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(organizations.onError(message));
  }
}

function* post(api, { payload }) {
  const response = yield call(api.post, payload.organization);

  if (response.ok) {
    yield put(organizations.getOrganizations());
  } else {
    const message = 'Não foi possível criar a filial';
    yield put(organizations.onError(message));
  }
}

function* edit(api, { payload }) {
  const response = yield call(api.put, payload.id, payload.organization);

  if (response.ok) {
    yield put(organizations.getOrganizations());
  } else {
    const message = 'Não foi possível editar a filial';
    yield put(organizations.onError(message));
  }
}

function* remove(api, { payload }) {
  const response = yield call(api.remove, payload.organization);

  if (response.ok) {
    yield put(organizations.getOrganizations());
  } else {
    const message = 'Não foi possível remover a filial';
    yield put(organizations.onError(message));
  }
}

function* watchList(api) {
  yield takeEvery(organizations.ACTIONS.GET_ORGANIZATIONS, list, api);
}

function* watchPost(api) {
  yield takeEvery(organizations.ACTIONS.CREATE_ORGANIZATION, post, api);
}

function* watchPut(api) {
  yield takeEvery(organizations.ACTIONS.EDIT_ORGANIZATION, edit, api);
}

function* watchDelete(api) {
  yield takeEvery(organizations.ACTIONS.DELETE_ORGANIZATION, remove, api);
}

export default function* rootSaga(api) {
  yield all([
    fork(watchList, api),
    fork(watchPost, api),
    fork(watchPut, api),
    fork(watchDelete, api),
  ]);
}
