import produce from 'immer';

import { ACTIONS } from './actions';

const INIT_STATE = {
  discounts: [],
  loading: false,
  error: '',
  discount: null,
};

export default (state = INIT_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case ACTIONS.GET_DISCOUNTS: {
        draft.loading = true;
        draft.error = null;
        break;
      }

      case ACTIONS.ON_SUCCESS: {
        draft.loading = false;
        draft.discounts = action.payload.discounts;
        draft.error = '';
        break;
      }

      case ACTIONS.ON_ERROR: {
        draft.loading = false;
        draft.discounts = [];
        draft.error = action.payload.message;
        break;
      }

      case ACTIONS.CREATE_DISCOUNT: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case ACTIONS.EDIT_DISCOUNT: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case ACTIONS.DELETE_DISCOUNT: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case ACTIONS.GET_DISCOUNT_BY_ID: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case ACTIONS.GET_DISCOUNT_BY_ID_SUCCESS: {
        draft.loading = false;
        draft.discount = action.payload.discount;
        draft.error = '';
        break;
      }

      default:
        return state;
    }
  });
