import produce from 'immer';

import {
  FUEL_LIST_REQUEST,
  FUEL_LIST_SUCCESS,
  FUEL_LIST_ERROR,
  FUEL_UPDATE_REQUEST,
  FUEL_UPDATE_SUCCESS,
  FUEL_UPDATE_ERROR,
  FUEL_RESET_STATE,
} from './actions';

const INIT_STATE = {
  fuels: {},
  loading: false,
  error: '',
  success: false,
};

export default (state = INIT_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case FUEL_LIST_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case FUEL_LIST_SUCCESS: {
        draft.loading = false;
        draft.fuels = action.payload.fuels;
        draft.error = '';
        break;
      }

      case FUEL_LIST_ERROR: {
        draft.loading = false;
        draft.fuels = {};
        draft.error = action.payload.message;
        break;
      }

      case FUEL_UPDATE_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case FUEL_UPDATE_SUCCESS: {
        draft.loading = false;
        draft.success = true;
        draft.error = '';
        break;
      }

      case FUEL_UPDATE_ERROR: {
        draft.loading = false;
        draft.success = false;
        draft.error = action.payload.message;
        break;
      }

      case FUEL_RESET_STATE: {
        draft.loading = false;
        draft.success = false;
        draft.error = '';
        break;
      }

      default:
        return state;
    }
  });
};
