export const GAS_STATION_USER_LIST_REQUEST = 'GAS_STATION_USER_LIST_REQUEST';
export const GAS_STATION_USER_LIST_SUCCESS = 'GAS_STATION_USER_LIST_SUCCESS';
export const GAS_STATION_USER_LIST_ERROR = 'GAS_STATION_USER_LIST_ERROR';

export const GAS_STATION_USER_REGISTER_REQUEST =
  'GAS_STATION_USER_REGISTER_REQUEST';
export const GAS_STATION_USER_REGISTER_SUCCESS =
  'GAS_STATION_USER_REGISTER_SUCCESS';
export const GAS_STATION_USER_REGISTER_ERROR =
  'GAS_STATION_USER_REGISTER_ERROR';

export const GAS_STATION_USER_DELETE_REQUEST =
  'GAS_STATION_USER_DELETE_REQUEST';
export const GAS_STATION_USER_DELETE_ERROR = 'GAS_STATION_USER_DELETE_ERROR';

export const GAS_STATION_USER_EDIT_REQUEST = 'GAS_STATION_USER_EDIT_REQUEST';
export const GAS_STATION_USER_EDIT_ERROR = 'GAS_STATION_USER_EDIT_ERROR';

export const listGasStationUserRequest = params => ({
  type: GAS_STATION_USER_LIST_REQUEST,
  payload: { params },
});

export const listGasStationUserSuccess = payload => ({
  type: GAS_STATION_USER_LIST_SUCCESS,
  payload,
});

export const listGasStationUserError = message => ({
  type: GAS_STATION_USER_LIST_ERROR,
  payload: { message },
});

export const registerGasStationUserRequest = (body, gasStationId) => ({
  type: GAS_STATION_USER_REGISTER_REQUEST,
  payload: { body, gasStationId },
});

export const registerGasStationUserSuccess = () => ({
  type: GAS_STATION_USER_REGISTER_SUCCESS,
});

export const registerGasStationUserError = message => ({
  type: GAS_STATION_USER_REGISTER_ERROR,
  payload: { message },
});

export const deleteGasStationUserRequest = (id, gasStationId) => ({
  type: GAS_STATION_USER_DELETE_REQUEST,
  payload: { id, gasStationId },
});

export const deleteGasStationUserError = message => ({
  type: GAS_STATION_USER_DELETE_ERROR,
  payload: { message },
});

export const editGasStationUserRequest = (id, body, gasStationId) => ({
  type: GAS_STATION_USER_EDIT_REQUEST,
  payload: { id, body, gasStationId },
});

export const editGasStationUserError = message => ({
  type: GAS_STATION_USER_EDIT_ERROR,
  payload: { message },
});
