import produce from 'immer';

import {
  GAS_STATION_USER_LIST_REQUEST,
  GAS_STATION_USER_LIST_SUCCESS,
  GAS_STATION_USER_LIST_ERROR,
  GAS_STATION_USER_REGISTER_REQUEST,
  GAS_STATION_USER_REGISTER_SUCCESS,
  GAS_STATION_USER_REGISTER_ERROR,
  GAS_STATION_USER_DELETE_REQUEST,
  GAS_STATION_USER_DELETE_ERROR,
  GAS_STATION_USER_EDIT_REQUEST,
  GAS_STATION_USER_EDIT_ERROR,
} from './actions';

const INIT_STATE = {
  list: [],
  currentPage: '',
  totalPages: '',
  totalItems: '',
  skipItems: 0,
  loading: false,
  error: '',
  success: false,
};

export default (state = INIT_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case GAS_STATION_USER_LIST_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case GAS_STATION_USER_LIST_SUCCESS: {
        draft.loading = false;
        draft.list = action.payload.list;
        draft.currentPage = action.payload.pages.currentPage;
        draft.totalPages = action.payload.pages.totalPages;
        draft.totalItems = action.payload.items.totalItems;
        draft.skipItems = action.payload.items.skipItems;
        draft.error = '';
        break;
      }

      case GAS_STATION_USER_LIST_ERROR: {
        draft.loading = false;
        draft.list = [];
        draft.error = action.payload.message;
        break;
      }

      case GAS_STATION_USER_REGISTER_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case GAS_STATION_USER_REGISTER_SUCCESS: {
        draft.loading = false;
        draft.error = '';
        break;
      }

      case GAS_STATION_USER_REGISTER_ERROR: {
        draft.loading = false;
        draft.error = action.payload.message;
        break;
      }

      case GAS_STATION_USER_DELETE_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case GAS_STATION_USER_DELETE_ERROR: {
        draft.loading = false;
        draft.error = action.payload.message;
        break;
      }

      case GAS_STATION_USER_EDIT_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case GAS_STATION_USER_EDIT_ERROR: {
        draft.loading = false;
        draft.error = action.payload.message;
        break;
      }

      default:
        return state;
    }
  });
};
