export const FUEL_LIST_REQUEST = 'FUEL_LIST_REQUEST';
export const FUEL_LIST_SUCCESS = 'FUEL_LIST_SUCCESS';
export const FUEL_LIST_ERROR = 'FUEL_LIST_ERROR';

export const FUEL_UPDATE_REQUEST = 'FUEL_UPDATE_REQUEST';
export const FUEL_UPDATE_SUCCESS = 'FUEL_UPDATE_SUCCESS';
export const FUEL_UPDATE_ERROR = 'FUEL_UPDATE_ERROR';

export const FUEL_RESET_STATE = 'FUEL_RESET_STATE';

export const listFuelRequest = () => ({
  type: FUEL_LIST_REQUEST,
});

export const listFuelSuccess = payload => ({
  type: FUEL_LIST_SUCCESS,
  payload,
});

export const listFuelError = message => ({
  type: FUEL_LIST_ERROR,
  payload: { message },
});

export const updateFuelRequest = (id, body) => ({
  type: FUEL_UPDATE_REQUEST,
  payload: { id, body },
});

export const updateFuelSuccess = () => ({
  type: FUEL_UPDATE_SUCCESS,
});

export const updateFuelError = message => ({
  type: FUEL_UPDATE_ERROR,
  payload: { message },
});

export const resetFuelState = () => ({
  type: FUEL_RESET_STATE,
});
