export const MONEY_TRANSFER_LIST_REQUEST = 'MONEY_TRANSFER_LIST_REQUEST';
export const MONEY_TRANSFER_LIST_SUCCESS = 'MONEY_TRANSFER_LIST_SUCCESS';
export const MONEY_TRANSFER_LIST_ERROR = 'MONEY_TRANSFER_LIST_ERROR';
export const MONEY_TRANSFER_TRANSACTIONS_LIST_REQUEST =
  'MONEY_TRANSFER_TRANSACTIONS_LIST_REQUEST';
export const MONEY_TRANSFER_TRANSACTIONS_LIST_SUCCESS =
  'MONEY_TRANSFER_TRANSACTIONS_LIST_SUCCESS';
export const MONEY_TRANSFER_TRANSACTIONS_LIST_ERROR =
  'MONEY_TRANSFER_TRANSACTIONS_LIST_ERROR';

export const listMoneyTransfer = params => ({
  type: MONEY_TRANSFER_LIST_REQUEST,
  payload: { params },
});

export const onListMoneyTransferSuccess = payload => ({
  type: MONEY_TRANSFER_LIST_SUCCESS,
  payload,
});

export const onListMoneyTransferError = message => ({
  type: MONEY_TRANSFER_LIST_ERROR,
  payload: { message },
});

export const listMoneyTransferTransactions = params => ({
  type: MONEY_TRANSFER_TRANSACTIONS_LIST_REQUEST,
  payload: { params },
});

export const onListMoneyTransferTransactionsSuccess = payload => ({
  type: MONEY_TRANSFER_TRANSACTIONS_LIST_SUCCESS,
  payload,
});

export const onListMoneyTransferTransactionsError = message => ({
  type: MONEY_TRANSFER_TRANSACTIONS_LIST_ERROR,
  payload: { message },
});
