export const ACTIONS = {
  GET_DISCOUNTS: 'GET_DISCOUNTS',
  ON_SUCCESS: 'ON_SUCCESS',
  ON_ERROR: 'ON_ERROR',
  CREATE_DISCOUNT: 'CREATE_DISCOUNT',
  EDIT_DISCOUNT: 'EDIT_DISCOUNT',
  DELETE_DISCOUNT: 'DELETE_DISCOUNT',
  GET_DISCOUNT_BY_ID: 'GET_DISCOUNT_BY_ID',
  GET_DISCOUNT_BY_ID_SUCCESS: 'GET_DISCOUNT_BY_ID_SUCCESS',
};

export const getDiscounts = params => ({
  type: ACTIONS.GET_DISCOUNTS,
  payload: { params },
});

export const onSuccess = discounts => ({
  type: ACTIONS.ON_SUCCESS,
  payload: { discounts },
});

export const onError = message => ({
  type: ACTIONS.ON_ERROR,
  payload: { message },
});

export const createDiscount = discount => ({
  type: ACTIONS.CREATE_DISCOUNT,
  payload: { discount },
});

export const editDiscount = (id, discount) => ({
  type: ACTIONS.EDIT_DISCOUNT,
  payload: { id, discount },
});

export const deleteDiscount = discount => ({
  type: ACTIONS.DELETE_DISCOUNT,
  payload: { discount },
});

export const getDiscountById = discount => ({
  type: ACTIONS.GET_DISCOUNT_BY_ID,
  payload: { discount },
});

export const getDiscountByIdSuccess = discount => ({
  type: ACTIONS.GET_DISCOUNT_BY_ID_SUCCESS,
  payload: { discount },
});
