import api from './api';

export const list = () => api.get('/superAdmin/gasStation');

export const post = payload => api.post('/superAdmin/gasStation', payload);

export const put = (id, payload) =>
  api.put(`/superAdmin/gasStation/${id}`, payload);

export const remove = id => api.delete(`/superAdmin/gasStation/${id}`);
