export const GET_REFUEL_BY_CODE_REQUEST = 'GET_REFUEL_BY_CODE_REQUEST';
export const GET_REFUEL_BY_CODE_SUCCESS = 'GET_REFUEL_BY_CODE_SUCCESS';
export const GET_REFUEL_BY_CODE_ERROR = 'GET_REFUEL_BY_CODE_ERROR';
export const REFUEL_CONFIRM_REQUEST = 'REFUEL_CONFIRM_REQUEST';
export const REFUEL_CONFIRM_SUCCESS = 'REFUEL_CONFIRM_SUCCESS';
export const REFUEL_CONFIRM_ERROR = 'REFUEL_CONFIRM_ERROR';
export const RESET_REFUEL = 'RESET_REFUEL';

export const getRefuelByCodeRequest = code => ({
  type: GET_REFUEL_BY_CODE_REQUEST,
  payload: { code },
});

export const getRefuelByCodeSuccess = payload => ({
  type: GET_REFUEL_BY_CODE_SUCCESS,
  payload,
});

export const getRefuelByCodeError = message => ({
  type: GET_REFUEL_BY_CODE_ERROR,
  payload: { message },
});

export const refuelConfirmRequest = payload => ({
  type: REFUEL_CONFIRM_REQUEST,
  payload,
});

export const refuelConfirmSuccess = payload => ({
  type: REFUEL_CONFIRM_SUCCESS,
  payload,
});

export const refuelConfirmError = message => ({
  type: REFUEL_CONFIRM_ERROR,
  payload: { message },
});

export const resetRefuel = () => ({
  type: RESET_REFUEL,
});
