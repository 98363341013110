import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';
import * as deposits from './actions';
import { getCurrentOrganization } from '../organizations/selectors';

function* listDeposits(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const response = yield call(
    api.list,
    APIParams({ ...payload.params, gasStationId: currentOrganization.id })
  );

  if (response.ok) {
    const list = response.data.docs;
    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(deposits.onListDepositsSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(deposits.onListDepositsError(message));
  }
}

export function* watchListDeposits(api) {
  yield takeEvery(deposits.DEPOSIT_LIST_REQUEST, listDeposits, api);
}

export default function* rootSaga(api) {
  yield all([fork(watchListDeposits, api)]);
}
