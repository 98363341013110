import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const listUser = parameters =>
  api.get(`/admin/gasStation/user${objectToURLParams(parameters)}`);

export const registerUser = (body, gasStationId) =>
  api.post(`/admin/gasStation/user?gasStationId=${gasStationId}`, {
    ...body,
  });

export const editUser = ({ id, body, gasStationId }) =>
  api.put(`/admin/gasStation/user/${id}?gasStationId=${gasStationId}`, body);

export const deleteUser = (id, gasStationId) =>
  api.delete(`/admin/gasStation/user/${id}?gasStationId=${gasStationId}`);
