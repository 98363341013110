import { all } from 'redux-saga/effects';
import api from '../services';
import authSagas from './auth/saga';
import userSagas from './user/saga';
import gasStationUserSagas from './gasStationUser/saga';
import transactionSagas from './transaction/saga';
import organizationsSagas from './organizations/saga';
import administratorsSagas from './administrators/saga';
import reportsSagas from './reports/saga';
import depositsSagas from './deposits/saga';
import moneyTransferSagas from './moneyTransfer/saga';
import mapsSagas from './maps/saga';
import hydrateSagas from './hydrate/saga';
import discountRateSagas from './discountRate/saga';
import fuelSagas from './fuel/saga';
import validateRefuel from './validateRefuel/saga';

export default function* rootSaga() {
  yield all([
    authSagas(api.auth),
    userSagas(),
    gasStationUserSagas(api.user),
    transactionSagas({
      transactions: api.transactions,
      admin: api.admin,
    }),
    organizationsSagas(api.organizations),
    administratorsSagas(api.admin),
    reportsSagas(api.report),
    depositsSagas(api.deposits),
    moneyTransferSagas(api.moneyTransfer),
    mapsSagas(),
    hydrateSagas(api.hydrate),
    discountRateSagas(api.discountRate),
    fuelSagas(api.fuel),
    validateRefuel(api.validateRefuel),
  ]);
}
