import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';
import {
  GAS_STATION_USER_LIST_REQUEST,
  GAS_STATION_USER_REGISTER_REQUEST,
  GAS_STATION_USER_DELETE_REQUEST,
  GAS_STATION_USER_EDIT_REQUEST,
  listGasStationUserRequest,
  listGasStationUserSuccess,
  listGasStationUserError,
  registerGasStationUserSuccess,
  registerGasStationUserError,
  editGasStationUserError,
  deleteGasStationUserError,
} from './actions';
import { getCurrentOrganization } from '../organizations/selectors';

function* listGasStationUser(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const users = yield call(
    api.listUser,
    APIParams({ ...payload.params, gasStationId: currentOrganization.id })
  );

  if (users.ok) {
    const list = users.data.docs;
    const { current: currentPage, total: totalPages } = users.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = users.data.items;

    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(listGasStationUserSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(listGasStationUserError(message));
  }
}

function* registerGasStationUser(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const apiRequest = yield call(
    api.registerUser,
    payload.body,
    currentOrganization.id
  );

  if (apiRequest.ok) {
    yield put(registerGasStationUserSuccess());
  } else {
    yield put(registerGasStationUserError(apiRequest.data));
  }
  yield put(listGasStationUserRequest({}));
}

function* deleteGasStationUser(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const response = yield call(
    api.deleteUser,
    payload.id,
    currentOrganization.id
  );

  if (response.ok) {
    yield put(listGasStationUserRequest({}));
  } else {
    const message = 'Não foi possível remover o administrador';
    yield put(deleteGasStationUserError(message));
  }
}

function* editGasStationUser(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const response = yield call(api.editUser, payload, currentOrganization.id);

  if (response.ok) {
    yield put(listGasStationUserRequest({}));
  } else {
    const message = 'Não foi possível editar o administrador';
    yield put(editGasStationUserError(message));
  }
}

export function* watchListGasStationUserRequest(api) {
  yield takeEvery(GAS_STATION_USER_LIST_REQUEST, listGasStationUser, api);
}

export function* watchRegisterGasStationUserRequest(api) {
  yield takeEvery(
    GAS_STATION_USER_REGISTER_REQUEST,
    registerGasStationUser,
    api
  );
}

function* watchDeleteGasStationUserRequest(api) {
  yield takeEvery(GAS_STATION_USER_DELETE_REQUEST, deleteGasStationUser, api);
}

function* watchEditGasStationUserRequest(api) {
  yield takeEvery(GAS_STATION_USER_EDIT_REQUEST, editGasStationUser, api);
}

export default function* rootSaga(api) {
  yield all([
    fork(watchListGasStationUserRequest, api),
    fork(watchRegisterGasStationUserRequest, api),
    fork(watchDeleteGasStationUserRequest, api),
    fork(watchEditGasStationUserRequest, api),
  ]);
}
