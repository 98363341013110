import produce from 'immer';

import { ACTIONS } from './actions';

const INIT_STATE = {
  isSuperAdmin: false,
  isAdmin: false,
  organizations: [],
  currentOrganization: null,
  loading: false,
  error: null,
};

export default (state = INIT_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case ACTIONS.GET_ORGANIZATIONS: {
        draft.loading = true;
        draft.error = null;
        break;
      }

      case ACTIONS.ON_SUCCESS: {
        draft.loading = false;
        draft.organizations = action.payload.organizations;
        draft.error = null;
        break;
      }

      case ACTIONS.ON_ERROR: {
        draft.loading = false;
        draft.organizations = [];
        draft.error = action.payload.message;
        break;
      }

      case ACTIONS.ENABLE_ADMIN: {
        draft.isAdmin = true;
        break;
      }

      case ACTIONS.ENABLE_SUPER_ADMIN: {
        draft.isSuperAdmin = true;
        break;
      }

      case ACTIONS.SELECT_ORGANIZATION: {
        draft.currentOrganization = action.payload.currentOrganization;
        break;
      }

      case ACTIONS.DESELECT_ORGANIZATION: {
        draft.currentOrganization = null;
        break;
      }

      case ACTIONS.CLEAR_ORGANIZATIONS: {
        draft.organizations = [];
        draft.currentOrganization = null;
        draft.isSuperAdmin = false;
        break;
      }

      case ACTIONS.CREATE_ORGANIZATION: {
        draft.loading = true;
        draft.error = null;
        break;
      }

      case ACTIONS.EDIT_ORGANIZATION: {
        draft.loading = true;
        draft.error = null;
        break;
      }

      case ACTIONS.DELETE_ORGANIZATION: {
        draft.loading = true;
        draft.error = null;
        break;
      }

      default:
        return state;
    }
  });
};
