import { all, takeEvery, put, call, fork } from 'redux-saga/effects';

import {
  FUEL_LIST_REQUEST,
  listFuelSuccess,
  listFuelError,
  FUEL_UPDATE_REQUEST,
  updateFuelSuccess,
  updateFuelError,
} from './actions';

function* listFuelRequest(api) {
  const response = yield call(api.list);

  if (response.ok) {
    const { fuels } = response.data;
    yield put(listFuelSuccess({ fuels }));
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(listFuelError(message));
  }
}

function* updateFuelRequest(api, { payload }) {
  const { id, body } = payload;
  const response = yield call(api.update, id, body);

  if (response.ok) {
    yield put(updateFuelSuccess());
  } else {
    const message = 'Não foi possível atualizar os preços';
    yield put(updateFuelError(message));
  }
}

function* watchListFuelRequest(api) {
  yield takeEvery(FUEL_LIST_REQUEST, listFuelRequest, api);
}

function* watchUpdateFuelRequest(api) {
  yield takeEvery(FUEL_UPDATE_REQUEST, updateFuelRequest, api);
}

export default function* rootSaga(api) {
  yield all([
    fork(watchListFuelRequest, api),
    fork(watchUpdateFuelRequest, api),
  ]);
}
