import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';
import * as moneyTransfer from './actions';
import { getCurrentOrganization } from '../organizations/selectors';

function* listMoneyTransfer(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const response = yield call(
    api.list,
    APIParams({ ...payload.params, gasStationId: currentOrganization.id })
  );

  if (response.ok) {
    const list = response.data.docs;
    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(moneyTransfer.onListMoneyTransferSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(moneyTransfer.onListMoneyTransferError(message));
  }
}

function* listMoneyTransferTransactions(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);

  const { transactionsIds } = payload.params;

  if (transactionsIds) {
    const response = yield call(
      api.listTransactions,
      APIParams({ transactionsIds, gasStationId: currentOrganization.id })
    );

    if (response.ok) {
      const list = response.data.docs;
      const { current: currentPage, total: totalPages } = response.data.pages;
      const { total: totalItems, skip: skipItems = 0 } = response.data.items;
      const pages = { currentPage: Number(currentPage), totalPages };
      const items = { totalItems, skipItems };

      yield put(
        moneyTransfer.onListMoneyTransferTransactionsSuccess({
          list,
          pages,
          items,
        })
      );
    } else {
      const message = 'Não foi possível carregar estes dados';

      yield put(moneyTransfer.onListMoneyTransferTransactionsError(message));
    }
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(moneyTransfer.onListMoneyTransferTransactionsError(message));
  }
}

export function* watchListMoneyTransfer(api) {
  yield takeEvery(
    moneyTransfer.MONEY_TRANSFER_LIST_REQUEST,
    listMoneyTransfer,
    api
  );
}

export function* watchListMoneyTransferTransactions(api) {
  yield takeEvery(
    moneyTransfer.MONEY_TRANSFER_TRANSACTIONS_LIST_REQUEST,
    listMoneyTransferTransactions,
    api
  );
}

export default function* rootSaga(api) {
  yield all([
    fork(watchListMoneyTransfer, api),
    fork(watchListMoneyTransferTransactions, api),
  ]);
}
