import produce from 'immer';

import {
  GET_REFUEL_BY_CODE_REQUEST,
  GET_REFUEL_BY_CODE_SUCCESS,
  GET_REFUEL_BY_CODE_ERROR,
  REFUEL_CONFIRM_REQUEST,
  REFUEL_CONFIRM_SUCCESS,
  REFUEL_CONFIRM_ERROR,
  RESET_REFUEL,
} from './actions';

const INIT_STATE = {
  data: {},
  loading: false,
  error: '',
  success: false,
  confirmRefuel: {
    loading: false,
    error: '',
    success: false,
  },
};

export default (state = INIT_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case GET_REFUEL_BY_CODE_REQUEST:
        draft.loading = true;
        draft.error = '';
        break;

      case GET_REFUEL_BY_CODE_SUCCESS:
        draft.loading = false;
        draft.data = action.payload.data;
        draft.error = '';
        break;

      case GET_REFUEL_BY_CODE_ERROR:
        draft.loading = false;
        draft.data = {};
        draft.error = action.payload.message;
        break;

      case REFUEL_CONFIRM_REQUEST:
        draft.confirmRefuel.loading = true;
        draft.confirmRefuel.error = '';
        break;

      case REFUEL_CONFIRM_SUCCESS:
        draft.confirmRefuel.loading = false;
        draft.confirmRefuel.success = true;
        draft.confirmRefuel.error = '';
        break;

      case REFUEL_CONFIRM_ERROR:
        draft.confirmRefuel.loading = false;
        draft.confirmRefuel.success = false;
        draft.confirmRefuel.error = action.payload.message;
        break;

      case RESET_REFUEL:
        draft.data = {};
        draft.error = '';
        draft.success = false;
        draft.loading = false;
        draft.confirmRefuel.loading = false;
        draft.confirmRefuel.success = false;
        draft.confirmRefuel.error = '';
        break;

      default:
        return state;
    }
  });
};
