import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';
import {
  ADMINISTRATORS_LIST_REQUEST,
  REGISTER_ADMINISTRATOR,
  DELETE_ADMINISTRATOR,
  EDIT_ADMINISTRATOR,
  editAdministratorError,
  registerAdministratorSuccess,
  deleteAdministratorError,
  listAdministrators,
  listAdministratorsSuccess,
  listAdministratorsError,
} from './actions';

function* listTransactions(api, { payload }) {
  const administrators = yield call(
    api.listAdminstrators,
    APIParams({ ...payload.params })
  );

  if (administrators.ok) {
    const list = administrators.data.docs;
    const {
      current: currentPage,
      total: totalPages,
    } = administrators.data.pages;

    const {
      total: totalItems,
      skip: skipItems = 0,
    } = administrators.data.items;

    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(listAdministratorsSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(listAdministratorsError(message));
  }
}

function* registerAdministrator(api, { payload }) {
  const apiRequest = yield call(api.registerAdministrator, payload.body);
  if (apiRequest.ok) {
    yield put(registerAdministratorSuccess());
  } else {
    yield put(listAdministratorsError(apiRequest.data));
  }
  yield put(listAdministrators());
}

function* deleteAdministrator(api, { payload }) {
  const response = yield call(api.deleteAdministrator, payload.id);

  if (response.ok) {
    yield put(listAdministrators());
  } else {
    const message = 'Não foi possível remover o administrador';
    yield put(deleteAdministratorError(message));
  }
}

function* editAdministrator(api, { payload }) {
  const response = yield call(api.editAdministrator, payload);

  if (response.ok) {
    yield put(listAdministrators());
  } else {
    const message = 'Não foi possível editar o administrador';
    yield put(editAdministratorError(message));
  }
}

export function* watchListTransactions(api) {
  yield takeEvery(ADMINISTRATORS_LIST_REQUEST, listTransactions, api);
}

export function* watchRegisterAdministrator(api) {
  yield takeEvery(REGISTER_ADMINISTRATOR, registerAdministrator, api);
}

function* watchDelete(api) {
  yield takeEvery(DELETE_ADMINISTRATOR, deleteAdministrator, api);
}

function* watchEdit(api) {
  yield takeEvery(EDIT_ADMINISTRATOR, editAdministrator, api);
}

export default function* rootSaga(api) {
  yield all([
    fork(watchListTransactions, api),
    fork(watchRegisterAdministrator, api),
    fork(watchDelete, api),
    fork(watchEdit, api),
  ]);
}
