export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const PERSIST_REHYDRATE = 'persist/REHYDRATE';
export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_ERROR = 'USER_LIST_ERROR';
export const AUTO_LOGOFF = 'AUTO_LOGOFF';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_ERROR = 'FORGET_PASSWORD_ERROR';
export const FORGET_PASSWORD_RESET = 'FORGET_PASSWORD_RESET';
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
export const CHANGE_PASSWORD_RESET = 'CHANGE_PASSWORD_RESET';
export const VALIDATE_SESSION_REQUEST = 'VALIDATE_SESSION_REQUEST';
export const VALIDATE_SESSION_SUCCESS = 'VALIDATE_SESSION_SUCCESS';
export const VALIDATE_SESSION_ERROR = 'VALIDATE_SESSION_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const listUser = params => ({
  type: USER_LIST_REQUEST,
  payload: { params },
});

export const listUserSuccess = payload => ({
  type: USER_LIST_SUCCESS,
  payload,
});

export const listUserError = message => ({
  type: USER_LIST_ERROR,
  payload: { message },
});

export const loginUser = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history },
});

export const loginUserSuccess = (token, user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: { token, user },
});

export const loginUserError = message => ({
  type: LOGIN_USER_ERROR,
  payload: { message },
});

export const registerUser = (user, history) => ({
  type: REGISTER_USER,
  payload: { user, history },
});

export const registerUserSuccess = user => ({
  type: REGISTER_USER_SUCCESS,
  payload: user,
});

export const registerUserError = message => ({
  type: REGISTER_USER_ERROR,
  payload: { message },
});

export const logoutUser = history => ({
  type: LOGOUT_USER,
  payload: { history },
});

export const autoLogoff = history => ({
  type: AUTO_LOGOFF,
  payload: { history },
});

export const resetForgetState = () => ({
  type: FORGET_PASSWORD_RESET,
});

export const forgetPassword = identifier => ({
  type: FORGET_PASSWORD,
  payload: { identifier },
});

export const forgetPasswordSuccess = () => ({
  type: FORGET_PASSWORD_SUCCESS,
});

export const forgetPasswordError = error => ({
  type: FORGET_PASSWORD_ERROR,
  payload: { error },
});

export const changePasswordRequest = ({ password, newPassword }) => ({
  type: CHANGE_PASSWORD_REQUEST,
  payload: { password, newPassword },
});

export const changePasswordSuccess = () => ({
  type: CHANGE_PASSWORD_SUCCESS,
});

export const changePasswordError = message => ({
  type: CHANGE_PASSWORD_ERROR,
  payload: { message },
});

export const validateSessionRequest = () => ({
  type: VALIDATE_SESSION_REQUEST,
});

export const validateSessionSuccess = () => ({
  type: VALIDATE_SESSION_SUCCESS,
});

export const validateSessionError = message => ({
  type: VALIDATE_SESSION_ERROR,
  payload: { message },
});

export const resetPassword = ({ token, password, history }) => ({
  type: RESET_PASSWORD,
  payload: { token, password, history },
});
export const resetPasswordSuccess = newPassword => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: newPassword,
});
export const resetPasswordError = message => ({
  type: RESET_PASSWORD_ERROR,
  payload: { message },
});
