import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const list = parameters =>
  api.get(`/admin/gasStation/discountRate${objectToURLParams(parameters)}`);

export const getById = id => api.get(`/admin/gasStation/discountRate/${id}`);

export const post = payload =>
  api.post('/admin/gasStation/discountRate', payload);

export const put = (id, payload) =>
  api.put(`/admin/gasStation/discountRate/${id}`, payload);

export const remove = id => api.delete(`/admin/gasStation/discountRate${id}`);
