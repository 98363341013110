import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';
import * as validateRefuel from './actions';
import { getCurrentOrganization } from '../organizations/selectors';

function* getRefuelByCode(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const response = yield call(
    api.getRefuel,
    APIParams({
      ...payload.params,
      code: payload.code,
      gasStationId: currentOrganization.id,
    })
  );

  if (response.ok) {
    const { data } = response;

    yield put(validateRefuel.getRefuelByCodeSuccess({ data }));
  } else {
    const message = 'Nenhum abastecimento encontrado com o código informado';

    yield put(validateRefuel.getRefuelByCodeError(message));
  }
}

function* confirmRefuel(api, { payload }) {
  const response = yield call(api.confirmRefuel, payload);

  if (response.ok) {
    yield put(validateRefuel.refuelConfirmSuccess());
  } else {
    const message = 'Não foi possível confirmar o abastecimento';

    yield put(validateRefuel.refuelConfirmError(message));
  }
}

export function* watchGetRefuel(api) {
  yield takeEvery(
    validateRefuel.GET_REFUEL_BY_CODE_REQUEST,
    getRefuelByCode,
    api
  );
}

export function* watchConfirmRefuel(api) {
  yield takeEvery(validateRefuel.REFUEL_CONFIRM_REQUEST, confirmRefuel, api);
}

export default function* rootSaga(api) {
  yield all([fork(watchGetRefuel, api), fork(watchConfirmRefuel, api)]);
}
