import React from 'react';
import IntlMessages from 'helpers/IntlMessages';
/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'pt';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
  { id: 'pt', name: 'Português - Brasil', direction: 'ltr' },
];

export const searchPath = '/app/pages/search';
export const servicePath = 'https://api.coloredstrategies.com';
export const buyUrl = 'https://www.litroz.com/solicitarnovacompra';

/*
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const isMultiColorActive = false;
export const defaultColor = 'light.blue';
export const defaultDirection = 'ltr';
export const isDarkSwitchActive = false;
export const themeColorStorageKey = '__theme_color';
export const themeRadiusStorageKey = '__theme_radius';
export const isDemo = false;

export const defaultPageSizes = [5, 10, 25, 50, 100];

export const billDateOptions = [
  // { label: <IntlMessages id="pages.weekly" />, value: () => '07', key: 1 },
  { label: <IntlMessages id="pages.fortnightly" />, value: () => '15', key: 2 },
  {
    label: <IntlMessages id="pages.monthly" />,
    value: (month, year) => new Date(year, month, 0).getDate(),
    key: 3,
  },
];

export const STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  DELETED: 'deleted',
};

export const STATUS_TRANSACTIONS = {
  APROVED: 'data.approved',
  PENDING: 'data.pending',
  CANCELLED: 'data.cancelled',
};

export const TRANSACTIONS_STATUS_TYPES = [
  {
    label: <IntlMessages id={STATUS_TRANSACTIONS.APROVED} />,
    value: 'active',
    key: 1,
  },
  {
    label: <IntlMessages id={STATUS_TRANSACTIONS.CANCELLED} />,
    value: 'inactive',
    key: 2,
  },
];

export const statusTypes = [
  { label: <IntlMessages id="active" />, value: true, key: 1 },
  { label: <IntlMessages id="inactive" />, value: false, key: 2 },
];

export const fuelTypes = [
  { label: <IntlMessages id="fuel.ethanol" />, value: 'ethanol', key: 1 },
  {
    label: <IntlMessages id="fuel.premiumEthanol" />,
    value: 'premiumEthanol',
    key: 2,
  },
  { label: <IntlMessages id="fuel.diesel" />, value: 'diesel', key: 3 },
  {
    label: <IntlMessages id="fuel.dieselS500" />,
    value: 'diesels500',
    key: 4,
  },
  {
    label: <IntlMessages id="fuel.carrierNaturalGas" />,
    value: 'carrierNaturalGas',
    key: 5,
  },
  { label: <IntlMessages id="fuel.gas" />, value: 'gas', key: 6 },
  {
    label: <IntlMessages id="fuel.premiumGas" />,
    value: 'premiumGas',
    key: 7,
  },
  { label: <IntlMessages id="fuel.arla" />, value: 'arla', key: 8 },
];

export const userCategories = [
  { label: <IntlMessages id="pages.driver" />, value: 'employee', key: 1 },
  { label: <IntlMessages id="pages.recipient" />, value: 'contractor', key: 2 },
];

export const STATUS_TYPES = [
  { label: <IntlMessages id={STATUS.ACTIVE} />, value: true, key: 1 },
  { label: <IntlMessages id={STATUS.INACTIVE} />, value: false, key: 2 },
  { label: <IntlMessages id={STATUS.DELETED} />, value: 'isDeleted', key: 3 },
];

export const DEBOUNCE_TIME = 1000;

export const DEFAULT_PAGE_SIZE = 10;

export const INITIAL_PAGE = 1;

export const DISCOUNT_TYPES = {
  ethanol: [
    {
      label: 'pages.value',
      value: 'value',
      id: 'ethanol-value',
      defaultChecked: true,
    },
    {
      label: 'pages.percent',
      value: 'percent',
      id: 'ethanol-percent',
      defaultChecked: false,
    },
    {
      label: 'pages.price',
      value: 'price',
      id: 'ethanol-price',
      defaultChecked: false,
    },
  ],
  premiumEthanol: [
    {
      label: 'pages.value',
      value: 'value',
      id: 'premiumEthanol-value',
      defaultChecked: true,
    },
    {
      label: 'pages.percent',
      value: 'percent',
      id: 'premiumEthanol-percent',
      defaultChecked: false,
    },
    {
      label: 'pages.price',
      value: 'price',
      id: 'premiumEthanol-price',
      defaultChecked: false,
    },
  ],
  gas: [
    {
      label: 'pages.value',
      value: 'value',
      id: 'gas-value',
      defaultChecked: true,
    },
    {
      label: 'pages.percent',
      value: 'percent',
      id: 'gas-percent',
      defaultChecked: false,
    },
    {
      label: 'pages.price',
      value: 'price',
      id: 'gas-price',
      defaultChecked: false,
    },
  ],
  premiumGas: [
    {
      label: 'pages.value',
      value: 'value',
      id: 'premiumGas-value',
      defaultChecked: true,
    },
    {
      label: 'pages.percent',
      value: 'percent',
      id: 'premiumGas-percent',
      defaultChecked: false,
    },
    {
      label: 'pages.price',
      value: 'price',
      id: 'premiumGas-price',
      defaultChecked: false,
    },
  ],
  diesel: [
    {
      label: 'pages.value',
      value: 'value',
      id: 'diesel-value',
      defaultChecked: true,
    },
    {
      label: 'pages.percent',
      value: 'percent',
      id: 'diesel-percent',
      defaultChecked: false,
    },
    {
      label: 'pages.price',
      value: 'price',
      id: 'diesel-price',
      defaultChecked: false,
    },
  ],
  dieselS500: [
    {
      label: 'pages.value',
      value: 'value',
      id: 'dieselS500-value',
      defaultChecked: true,
    },
    {
      label: 'pages.percent',
      value: 'percent',
      id: 'dieselS500-percent',
      defaultChecked: false,
    },
    {
      label: 'pages.price',
      value: 'price',
      id: 'dieselS500-price',
      defaultChecked: false,
    },
  ],
  arla: [
    {
      label: 'pages.value',
      value: 'value',
      id: 'arla-value',
      defaultChecked: true,
    },
    {
      label: 'pages.percent',
      value: 'percent',
      id: 'arla-percent',
      defaultChecked: false,
    },
    {
      label: 'pages.price',
      value: 'price',
      id: 'arla-price',
      defaultChecked: false,
    },
  ],
  carrierNaturalGas: [
    {
      label: 'pages.value',
      value: 'value',
      id: 'carrierNaturalGas-value',
      defaultChecked: true,
    },
    {
      label: 'pages.percent',
      value: 'percent',
      id: 'carrierNaturalGas-percent',
      defaultChecked: false,
    },
    {
      label: 'pages.price',
      value: 'price',
      id: 'carrierNaturalGas-price',
      defaultChecked: false,
    },
  ],
};

export const FUEL_TYPES = [
  { label: <IntlMessages id="fuel.ethanol" />, value: 'ethanol', key: 1 },
  {
    label: <IntlMessages id="fuel.premiumEthanol" />,
    value: 'premiumEthanol',
    key: 2,
  },
  { label: <IntlMessages id="fuel.diesel" />, value: 'diesel', key: 3 },
  {
    label: <IntlMessages id="fuel.dieselS500" />,
    value: 'dieselS500',
    key: 4,
  },
  {
    label: <IntlMessages id="fuel.carrierNaturalGas" />,
    value: 'carrierNaturalGas',
    key: 5,
  },
  { label: <IntlMessages id="fuel.gas" />, value: 'gas', key: 6 },
  {
    label: <IntlMessages id="fuel.premiumGas" />,
    value: 'premiumGas',
    key: 7,
  },
  { label: <IntlMessages id="fuel.arla" />, value: 'arla', key: 8 },
];

export const PASSWORD = 'password';
