export const ACTIONS = {
  GET_ORGANIZATIONS: 'SUPER_ADMIN_GET_ORGANIZATIONS',
  ON_SUCCESS: 'SUPER_ADMIN_SUCCESS',
  ON_ERROR: 'SUPER_ADMIN_ERROR',
  SELECT_ORGANIZATION: 'SUPER_ADMIN_SELECT_ORGANIZATION',
  DESELECT_ORGANIZATION: 'SUPER_ADMIN_DESELECT_ORGANIZATION',
  CLEAR_ORGANIZATIONS: 'SUPER_ADMIN_CLEAR_ORGANIZATIONS',
  CREATE_ORGANIZATION: 'SUPER_ADMIN_POST_ORGANIZATION',
  EDIT_ORGANIZATION: 'SUPER_ADMIN_PUT_ORGANIZATION',
  DELETE_ORGANIZATION: 'SUPER_ADMIN_DELETE_ORGANIZATION',
  ENABLE_ADMIN: 'ENABLE_ADMIN',
  ENABLE_SUPER_ADMIN: 'ENABLE_SUPER_ADMIN',
};

export const getOrganizations = () => ({
  type: ACTIONS.GET_ORGANIZATIONS,
});

export const enableAdmin = () => ({
  type: ACTIONS.ENABLE_ADMIN,
});

export const enableSuperAdmin = () => ({
  type: ACTIONS.ENABLE_SUPER_ADMIN,
});

export const onSuccess = organizations => ({
  type: ACTIONS.ON_SUCCESS,
  payload: { organizations },
});

export const onError = message => ({
  type: ACTIONS.ON_ERROR,
  payload: { message },
});

export const onSelectOrganization = currentOrganization => ({
  type: ACTIONS.SELECT_ORGANIZATION,
  payload: { currentOrganization },
});

export const onDeselectOrganization = () => ({
  type: ACTIONS.DESELECT_ORGANIZATION,
});

export const clearOrganizations = () => ({
  type: ACTIONS.CLEAR_ORGANIZATIONS,
});

export const createOrganization = organization => ({
  type: ACTIONS.CREATE_ORGANIZATION,
  payload: { organization },
});

export const editOrganization = (id, organization) => ({
  type: ACTIONS.EDIT_ORGANIZATION,
  payload: { id, organization },
});

export const deleteOrganization = organization => ({
  type: ACTIONS.DELETE_ORGANIZATION,
  payload: { organization },
});
