import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';
import { TRANSACTION_LIST_REQUEST } from '../actions';

import { listTransactionSuccess, listTransactionError } from './actions';
import { getCurrentOrganization } from '../organizations/selectors';

function* listTransactions(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);

  const transactions = yield call(
    api.transactions.list,
    APIParams({
      ...payload.params,
      gasStationId: currentOrganization.id,
    })
  );

  if (transactions.ok) {
    const list = transactions.data.docs;
    const { current: currentPage, total: totalPages } = transactions.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = transactions.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(listTransactionSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(listTransactionError(message));
  }
}

export function* watchListTransactions(api) {
  yield takeEvery(TRANSACTION_LIST_REQUEST, listTransactions, api);
}

export default function* rootSaga(api) {
  yield all([fork(watchListTransactions, api)]);
}
