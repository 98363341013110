export const DEPOSIT_LIST_REQUEST = 'DEPOSIT_LIST_REQUEST';
export const DEPOSIT_LIST_SUCCESS = 'DEPOSIT_LIST_SUCCESS';
export const DEPOSIT_LIST_ERROR = 'DEPOSIT_LIST_ERROR';

export const listDeposits = params => ({
  type: DEPOSIT_LIST_REQUEST,
  payload: { params },
});

export const onListDepositsSuccess = payload => ({
  type: DEPOSIT_LIST_SUCCESS,
  payload,
});

export const onListDepositsError = message => ({
  type: DEPOSIT_LIST_ERROR,
  payload: { message },
});
