import * as auth from './auth';
import * as user from './user';
import * as transactions from './transactions';
import * as organizations from './organizations';
import * as admin from './admin';
import * as report from './report';
import * as deposits from './deposits';
import * as moneyTransfer from './moneyTransfer';
import * as hydrate from './hydrate';
import * as discountRate from './discountRate';
import * as fuel from './fuel';
import * as validateRefuel from './validateRefuel';

export default {
  auth,
  user,
  transactions,
  organizations,
  admin,
  report,
  deposits,
  moneyTransfer,
  hydrate,
  discountRate,
  fuel,
  validateRefuel,
};
